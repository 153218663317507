


































import { Component, Prop, Vue } from "vue-property-decorator";
import gImage from "@/components/g-image.vue";
import gEmpty from "@/components/g-empty.vue";
import EventBus from "@/assets/js/EventBus";

@Component({
  components: {
    gImage,
    gEmpty,
  },
  filters: {
    resize: (url: string) => url.replace(/@.*/, "@100h_1l"),
  },
})
export default class gRoomList extends Vue {
  @Prop() private list!: any;
  @Prop() private loading!: boolean;
  @Prop() private callback!: any;
  private pos = 0;
  private isUserScroll = true;
  private timer: null | number = null;

  scroll(event: any): void {
    if (event.target && this.isEqualTo(this.pos, event.target.scrollTop)) {
      return;
    }

    let scrollBox = document.getElementById("scroll-box")!;
    let container = scrollBox.parentElement!;

    if (this.isUserScroll) {
      if (this.timer) {
        return;
      }
      this.timer = window.setTimeout(() => {
        this.timer = null;
        this.pos = event.target.scrollTop;
        this.$socket?.dispatchEvent("listScroll", this.pos);
      }, 100);
    } else {
      this.pos = event;
      scrollBox.scrollTop = this.pos;
      this.isUserScroll = true;
    }

    if (this.pos + container.clientHeight > scrollBox.scrollHeight - 60) {
      if (this.callback) this.callback();
    }
  }

  isEqualTo(a: number, b: number): boolean {
    const precision = 0.00001;
    return Math.abs(a - b) < precision;
  }

  toGuideDetail(id: string): void {
    EventBus.$emit("toGuideDetail", id);
    this.$socket?.dispatchEvent("toGuideDetail", id);
  }

  // 绑定事件
  handleMessage(): void {
    EventBus.$on("listScroll", (data: any) => {
      this.isUserScroll = false;
      this.scroll(data);
    });
  }

  mounted() {
    if (this.$socket) this.handleMessage();
  }
}
